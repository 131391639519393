import { render, staticRenderFns } from "./MethodIntro.vue?vue&type=template&id=3efc9f57&scoped=true&"
import script from "./MethodIntro.vue?vue&type=script&lang=ts&"
export * from "./MethodIntro.vue?vue&type=script&lang=ts&"
import style0 from "./MethodIntro.vue?vue&type=style&index=0&id=3efc9f57&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3efc9f57",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonWrapper: require('/opt/build/repo/components/utilities/buttons/ButtonWrapper.vue').default,MediaHandler: require('/opt/build/repo/components/utilities/media/MediaHandler.vue').default})
